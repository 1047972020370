
import {
    defineComponent, reactive, ref, computed, onBeforeMount,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import Screen from '@/components/layout/Screen.vue';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import Item from '@/domain/Item';
import SupplierShippingTransactionHead from '@/modules/partner-engagement/view/supplier-shipping/components/SupplierShippingTransactionHead.vue';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import Shipment from '@/domain/Shipment';
import coreStore from '@/store/CoreStore';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import TransactionService from '@/services/TransactionService';
import router from '@/router';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import useUnitLoadGrouping from '@/composable/useUnitLoadGrouping';
import ItemQuantity from '@/interfaces/ItemQuantity';
import TransactionQuantityNumberInput from '@/components/TransactionQuantityNumberInput.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import { ItemType } from '@/domain/enums/ItemType';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    shipment: Shipment;
    transaction: Transaction;
    loading: boolean;
    itemList: Array<ItemQuantity | ItemQuantity[]>;
    disputing: boolean;
    saving: boolean;
    showItemSearch: boolean;
};

export default defineComponent({
    name: 'pem-confirm-dispute-transaction-screen',
    components: {
        Thumbnail,
        BSpinner,
        BTable,
        Screen,
        SupplierShippingTransactionHead,
        TransactionQuantityNumberInput,
        ItemPicker,
    },
    props: {
        modelValue: {
            type: Transaction,
            default: () => new Transaction(),
        },
    },
    setup(props) {
        const shipmentService = new SupplierShipmentService();
        const transactionService = new TransactionService();
        const { profileStore } = coreStore.getInstance();
        const { confirm } = useDialogBox();
        const notification = useNotification();

        const { validateForm, validationResult } = useValidator<Transaction>('transaction-dispute');

        const { getTransactionLinesInUnitLoadGroupOrder, mapTransactionLinesForTable } = useUnitLoadGrouping();

        const originalShipmentStringified = ref<string>('');

        const state = reactive<State>({
            shipment: new Shipment(),
            transaction: new Transaction(),
            loading: false,
            itemList: [],
            disputing: false,
            saving: false,
            showItemSearch: false,
        });

        async function fetchShipmentDetails(transactionId: number) {
            const response = await shipmentService.getShipmentDetailsForTransaction(transactionId);
            if (response.success) {
                state.shipment = response.shipmentDetails;
                state.shipment.fromLocation = props.modelValue?.fromLocation;
            }
        }

        async function populateTransactionItems(transaction: Transaction) {
            // if state.disputing, use TransactionStatus.DISPUTED so received qty is set correctly. transaction status isn't actually set to DISPUTED until user saves
            state.itemList = mapTransactionLinesForTable(getTransactionLinesInUnitLoadGroupOrder(transaction), state.disputing ? TransactionStatus.DISPUTED : state.transaction.status);
        }

        function showItemSearch() {
            state.showItemSearch = true;
        }

        async function addItem(data: { item: Item; quantity: number }) {
            const itemAlreadyAdded = state.transaction.transactionLines.find((line) => line.item.id === data.item.id);
            if (!itemAlreadyAdded) {
                state.transaction.addEmptyLine(data.item);

                // manually update itemList instead of using getTransactionLinesInUnitLoadGroupOrder to avoid unit load grouping issue when transactionLine.id undefined
                const newLine = state.transaction.transactionLines[state.transaction.transactionLines.length - 1] as TransactionLine;
                const newItems = mapTransactionLinesForTable([newLine], state.disputing ? TransactionStatus.DISPUTED : state.transaction.status);
                state.itemList.push(...newItems);
            } else {
                notification.showError(`${data.item.name} ${getTranslation('core.common.alreadyAdded')}`);
            }
            state.showItemSearch = false;
        }

        async function initExisting() {
            state.loading = true;

            state.transaction = props.modelValue;
            await fetchShipmentDetails(state.transaction.id);
            await populateTransactionItems(state.transaction as Transaction);
            state.shipment.transactions.push(state.transaction);
            originalShipmentStringified.value = JSON.stringify(state.shipment);

            state.loading = false;
        }

        onBeforeMount(async () => {
            if (props.modelValue) {
                await initExisting();
            }
        });

        const isDirty = computed((): boolean => JSON.stringify(state.shipment) !== originalShipmentStringified.value);

        const transactionLineFields = computed(
            (): Array<BTableField<Record<string, string>>> => (state.disputing || state.transaction.status === TransactionStatus.DISPUTED
                ? [
                    { key: 'imageUrlThumb', label: getTitleCaseTranslation('core.common.image') },
                    { key: 'name', label: getTitleCaseTranslation('core.domain.name') },
                    { key: 'shortName', label: getTitleCaseTranslation('core.domain.shortName') },
                    { key: 'plannedQuantity', label: getTitleCaseTranslation('core.domain.plannedQuantity') },
                    { key: 'actualQuantity', label: getTitleCaseTranslation('core.domain.actualQuantity') },
                    { key: 'receivedQuantity', label: getTitleCaseTranslation('core.domain.receivedQuantity') },
                ]
                : [
                    { key: 'imageUrlThumb', label: getTitleCaseTranslation('core.common.image') },
                    { key: 'name', label: getTitleCaseTranslation('core.domain.name') },
                    { key: 'shortName', label: getTitleCaseTranslation('core.domain.shortName') },
                    { key: 'plannedQuantity', label: getTitleCaseTranslation('core.domain.plannedQuantity') },
                    { key: 'actualQuantity', label: getTitleCaseTranslation('core.domain.actualQuantity') },
                ]),
        );

        async function itemReceivedQuantityChange(itemQty: ItemQuantity, qty: number) {
            state.transaction.setReceivedQuantity({ quantity: qty, id: itemQty.id } as Item, qty, itemQty.unitLoadParentId);

            // manually update itemList instead of using getTransactionLinesInUnitLoadGroupOrder to avoid unit load grouping issue when transactionLine.id undefined
            const item = state.itemList.find((x) => (x as ItemQuantity).id === itemQty.id && (!itemQty.unitLoadParentId || (x as ItemQuantity).unitLoadParentId === itemQty.unitLoadParentId));
            if (item) {
                (item as ItemQuantity).receivedQuantity = qty;
            }
        }

        function setReceivedToActualQuantity() {
            state.itemList.flat().forEach((item) => {
                if (item.actualQuantity !== undefined && item.actualQuantity !== null) {
                    state.transaction.setReceivedQuantity(
                        {
                            quantity: item.actualQuantity,
                            id: item.id,
                        } as Item,
                        item.actualQuantity,
                        item.unitLoadParentId,
                    );
                }
            });
        }

        function cancelDispute() {
            if (props.modelValue) {
                props.modelValue.disputeNotes = '';
                props.modelValue.disputeImages = [];
            }
            state.disputing = false;
        }

        function showDisputeFields() {
            setReceivedToActualQuantity();
            state.disputing = true;
        }

        async function goToTransactionList() {
            await router.push({ name: PartnerEngagementRouteTypes.TRANSACTION.LISTALL });
        }

        async function saveDisputedTransaction() {
            validateForm(state.transaction as Transaction);

            if (validationResult.isValid) {
                state.saving = true;

                const response = await transactionService.disputeTransaction(state.transaction as Transaction);
                if (response) {
                    await goToTransactionList();
                } else {
                    state.transaction.clearTransactionLines();
                }

                state.saving = false;
            }
        }

        async function confirmLeave() {
            if (isDirty.value) {
                return confirm({
                    title: getTitleCaseTranslation('core.common.areYouSure'),
                    message: getTranslation('core.common.allUnsavedDataWillBeLostWhenYouLeaveThisPage'),
                });
            }
            return true;
        }

        async function cancel() {
            await goToTransactionList();
        }

        onBeforeRouteLeave(async () => confirmLeave());

        async function goBack() {
            await goToTransactionList();
        }

        async function confirmTransaction() {
            state.saving = true;

            setReceivedToActualQuantity();
            const response = await transactionService.confirmTransaction(state.transaction as Transaction);
            if (response) {
                await goToTransactionList();
            }

            state.saving = false;
        }

        const navbarTitle = computed((): string => {
            if (state.transaction.id) {
                return getTranslation('core.common.transactionNumberTitle', state.transaction.id, Formatter.GetFriendlyValue(state.transaction.status));
            }

            return '';
        });

        return {
            state,
            goToTransactionList,
            isDirty,
            cancel,
            goBack,
            profileStore,
            confirmTransaction,
            saveDisputedTransaction,
            showDisputeFields,
            cancelDispute,
            transactionLineFields,
            TransactionStatus,
            validationResult,
            navbarTitle,
            itemReceivedQuantityChange,
            getTitleCaseTranslation,
            showItemSearch,
            addItem,
            ItemType,
        };
    },
});
