import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7efb4702"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transaction-container" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "transaction-footer"
}
const _hoisted_4 = { class: "button-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_supplier_shipping_transaction_head = _resolveComponent("supplier-shipping-transaction-head")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_transaction_quantity_number_input = _resolveComponent("transaction-quantity-number-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_screen = _resolveComponent("screen")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_screen, {
      id: "supplier-inbound-transaction-screen",
      full: "",
      title: _ctx.navbarTitle,
      "go-back": _ctx.goBack
    }, {
      footer: _withCtx(() => [
        (!_ctx.state.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.state.transaction.canDispute(_ctx.profileStore.userLocation) && !_ctx.state.disputing)
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 0,
                      variant: "primary",
                      disabled: _ctx.state.saving,
                      onClick: _ctx.showDisputeFields
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.startDispute')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.state.disputing)
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 1,
                      variant: "primary",
                      disabled: _ctx.state.saving,
                      onClick: _ctx.cancelDispute
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancelDispute')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.state.disputing)
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 2,
                      variant: "primary",
                      disabled: _ctx.state.saving,
                      onClick: _ctx.saveDisputedTransaction
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submitDispute')) + " ", 1),
                        (_ctx.state.saving)
                          ? (_openBlock(), _createBlock(_component_b_spinner, {
                              key: 0,
                              small: ""
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.state.disputing && _ctx.state.transaction.canConfirm(_ctx.profileStore.userLocation))
                  ? (_openBlock(), _createBlock(_component_b_button, {
                      key: 3,
                      variant: "primary",
                      disabled: _ctx.state.saving,
                      onClick: _ctx.confirmTransaction
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.confirm')) + " ", 1),
                        (_ctx.state.saving)
                          ? (_openBlock(), _createBlock(_component_b_spinner, {
                              key: 0,
                              small: ""
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.state.loading)
            ? (_openBlock(), _createBlock(_component_supplier_shipping_transaction_head, {
                key: 0,
                shipment: _ctx.state.shipment,
                transaction: _ctx.state.transaction,
                disputing: _ctx.state.disputing || _ctx.state.transaction.status === _ctx.TransactionStatus.DISPUTED,
                "transaction-validation-result": _ctx.validationResult,
                style: {"margin-bottom":"25px"}
              }, null, 8, ["shipment", "transaction", "disputing", "transaction-validation-result"]))
            : _createCommentVNode("", true),
          _createVNode(_component_b_table, {
            "table-key": (_ctx.state.transaction.fromLocationId + _ctx.state.transaction.toLocationId).toString(),
            loading: _ctx.state.loading,
            items: _ctx.state.itemList,
            fields: _ctx.transactionLineFields
          }, {
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(plannedQuantity)": _withCtx((row) => [
              (!row.item.isUnitLoad)
                ? (_openBlock(), _createBlock(_component_transaction_quantity_number_input, {
                    key: 0,
                    disabled: "",
                    item: row.item,
                    "model-value": row.item.plannedQuantity,
                    "transaction-status": _ctx.state.transaction.status,
                    "hide-stepper": ""
                  }, null, 8, ["item", "model-value", "transaction-status"]))
                : _createCommentVNode("", true)
            ]),
            "cell(actualQuantity)": _withCtx((row) => [
              (!row.item.isUnitLoad)
                ? (_openBlock(), _createBlock(_component_transaction_quantity_number_input, {
                    key: 0,
                    disabled: "",
                    item: row.item,
                    "model-value": row.item.actualQuantity,
                    "transaction-status": _ctx.state.transaction.status,
                    "hide-stepper": ""
                  }, null, 8, ["item", "model-value", "transaction-status"]))
                : _createCommentVNode("", true)
            ]),
            "cell(receivedQuantity)": _withCtx((row) => [
              (!row.item.isUnitLoad)
                ? (_openBlock(), _createBlock(_component_number_input, {
                    key: 0,
                    "model-value": row.item.receivedQuantity,
                    disabled: _ctx.state.transaction.hasShipped && _ctx.state.transaction.status === _ctx.TransactionStatus.DISPUTED,
                    style: {"width":"120px","margin":"10px 0px"},
                    "hide-stepper": "",
                    onChange: (qty) => _ctx.itemReceivedQuantityChange(row.item, qty)
                  }, null, 8, ["model-value", "disabled", "onChange"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2))
            ]),
            _: 1
          }, 8, ["table-key", "loading", "items", "fields"]),
          (_ctx.state.disputing)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 1,
                class: "add-container",
                variant: "secondary",
                onClick: _ctx.showItemSearch
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addItem')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["title", "go-back"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showItemSearch,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showItemSearch) = $event)),
      centered: "",
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.button.addItem')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_item_picker, {
          "item-type": _ctx.ItemType.SHIPPING,
          "allow-quantity-change": false,
          shipping: { fromLocationId: _ctx.state.transaction.fromLocation.id, toLocationId: _ctx.state.transaction.toLocation.id },
          "ignore-combined-containers": "",
          onOnSelect: _ctx.addItem
        }, null, 8, ["item-type", "shipping", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}